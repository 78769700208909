const Production = {
  state: () => ({
    ProductionItemCode: "",
    ProductionModelId: "",
    usableStatus: "",
  }),
  mutations: {
    SET_ProductionItemCode: (state: any, itemCode: string | number) => {
      state.ProductionItemCode = itemCode;
    },
    SET_ProductionModelId: (state: any, ModelId: string | number) => {
      state.ProductionModelId = ModelId;
    },
    SET_usableStatus: (state: any, usableStatus: string | number) => {
      state.usableStatus = usableStatus;
    },
  },
  getters: {},
  actions: {},
};

export default Production;
