const dap = <any>{
  init: (fname: any, param: any) => dap[fname](param),
  //工件类型
  byPage: (param: any) => ({
    method: "/saas/website-space/dapDataShow/query/byPage", // 接口地址
    data: param, // 请求参数
  }),

  // 异常信息
  LnfoAlert: (param: any) => ({
    // method: "/saas/website-space/dapDataShow/info/alert", // 接口地址
    method: "/saas/website-space/dapDataShow/info/alert", // 接口地址
    data: param, // 请求参数
  }),
  project: (param: any) => ({
    // method: "/saas/website-space/dapDataShow/info/alert", // 接口地址
    method: "/saas/website-space/dapDataShow/query/project", // 接口地址
    data: param, // 请求参数
  }),
};
export default dap;
