import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
  createWebHashHistory,
} from "vue-router";
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "main",
    redirect: "/login",
    component: () => import("@/views/mainPage.vue"),
    children: [
      // 登录页
      {
        path: "/login",
        name: "login",
        meta: {
          title: "登录",
          // 是否显示返回按钮
          IsButton: false,
        },
        component: () => import("@/views/login/index.vue"),
      },
      {
        path: "/AutoMonitor",
        name: "AutoMonitor",
        meta: {
          title: "自动化监控",
        },
        component: () => import("@/views/AutoMonitor/index.vue"),
      },
      {
        path: "/ProductionManagement",
        name: "ProductionManagement",
        meta: {
          title: "生产管理",
        },
        component: () => import("@/views/ProductionManagement/index.vue"),
      },
      {
        path: "/Craft",
        name: "Craft",
        meta: {
          title: "工艺监控",
        },
        component: () => import("@/views/Craft/index.vue"),
      },
      {
        path: "/Personnel",
        name: "Personnel",
        meta: {
          title: "人员管理",
        },
        component: () => import("@/views/Personnel/index.vue"),
      },
      {
        path: "/Operate",
        name: "Operate",
        meta: {
          title: "人员管理",
        },
        component: () => import("@/views/Operate/index.vue"),
      },

      {
        path: "/home",
        name: "home",
        meta: {
          title: "首页",
          // 是否显示返回按钮
          IsButton: false,
        },
        component: () => import("@/views/Home/home.vue"),
      },
    ],
  },
];

const router = createRouter({
  // history:  createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(),
  routes,
});
import store from "@/store/index";
import { ElMessage } from "element-plus";
router.beforeEach((to: any, from, next) => {
  // 通过 isNeedLogin 判断当前路由导航是否需要登录
  if (to.query.transferData) {
    let transferData = JSON.parse(to?.query?.transferData);
    store.commit("SET_TOKEN", transferData?.Token);
    store.commit("SET_companyId", transferData?.companyId);
    store.commit("GetNav", 1);
    store.commit("SET_projectId", 10000);
    store.commit("SET_popoverShow", true);
    // sessionStorage.setItem("popoverShow",  true);
  }
  // if (to.query.token) {
  //   store.commit("SET_TOKEN", to.query.token);
  //   store.commit("SET_companyId", to.query.companyId);
  //   store.commit("GetNav", to.query.NavId);
  //   store.commit("SET_projectId", 10000);

  //   if (to.query.isiframe) {
  //     sessionStorage.setItem("isiframe", JSON.stringify(to.query.isiframe));
  //   }
  // }
  next();
});

export default router;
