import { createStore } from "vuex";
import Production from "./modules/Production";

export default createStore({
  state: {
    NavId: sessionStorage.getItem("NavId")
      ? sessionStorage.getItem("NavId")
      : "1",
    token: JSON.parse(JSON.stringify(sessionStorage.getItem("token"))) || null,
    companyId:
      JSON.parse(JSON.stringify(sessionStorage.getItem("companyId"))) || null,
    projectId:
      JSON.parse(JSON.stringify(sessionStorage.getItem("projectId"))) ||
      "10000", //1000威乐项目

    itemCode: 0,
    modelId: "",
    permissionId:
      JSON.parse(JSON.stringify(sessionStorage.getItem("permissionId"))) ||
      null,
    popoverShow:
      JSON.parse(JSON.stringify(sessionStorage.getItem("popoverShow"))) ||
      false,
  },
  mutations: {
    GetNav(state, value) {
      state.NavId = value;
      sessionStorage.NavId = value;
    },
    SET_TOKEN: (state: any, token: string) => {
      state.token = token;
      sessionStorage.token = token;
    },
    SET_permissionId: (state: any, permissionId: string | number) => {
      state.permissionId = permissionId;
      sessionStorage.permissionId = permissionId;
    },
    SET_projectId: (state: any, projectId: string) => {
      state.projectId = projectId;
      sessionStorage.projectId = projectId;
    },
    SET_itemCode: (state: any, itemCode: string | number) => {
      state.itemCode = itemCode;
    },
    SET_modelId: (state: any, modelId: string | number) => {
      state.modelId = modelId;
    },
    SET_companyId: (state: any, companyId: string | number) => {
      state.companyId = companyId;
      sessionStorage.companyId = companyId;
    },
    SET_popoverShow: (state: any, popoverShow: string | boolean) => {
      state.popoverShow = popoverShow;
      sessionStorage.popoverShow = popoverShow;
    },
  },
  actions: {},
  modules: {
    Production,
  },
});
