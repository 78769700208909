const craft = <any>{
  init: (fname: any, param: any) => craft[fname](param),
  //获取时间轴
  getTime: (param: any) => ({
    // method: "/saas/website-space/itemTemperatureCurve/get/time-line", // 接口地址
    method: "/saas/website-space/itemTemperatureCurve/get/time-line", // 接口地址
    data: param, // 请求参数
  }),
  // 查询温度曲线
  temperatureCurve: (param: any) => ({
    method: "/saas/website-space/itemTemperatureCurve/get/curve/by/item-code", // 接口地址
    data: param, // 请求参数
  }),
  // 查询时间轴
  TimerShaft: (param: any) => ({
    method: "/saas/website-space/itemTemperatureCurve/get/time-line/item-codes", // 接口地址
    data: param, // 请求参数
  }),
};
export default craft;
