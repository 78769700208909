const User = <any>{
  init: (fname: any, param: any) => User[fname](param),
  //  用户登录
  Login: (param: any) => ({
    method: "/saas/website-space/user/login", // 接口地址
    data: param, // 请求参数
  }),
  // 退出
  logout: (param: any) => ({
    method: "/saas/website-space/user/logout", // 接口地址
    data: param, // 请求参数
  }),
};
export default User;
